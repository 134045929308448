.bady {
    background-image: url('./components/images/5415455.png'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .image-container {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }

  .image-container img {
    width: 50%;
    max-width: 300px;
    height: 500px;
  }

  .left-image {
    left: 0;
  }

  .right-image {
    right: 0;
  }
  

  .black-foto {
    background-image: url('./components/images/black_foto.png'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 500;
  }
  .red-foto {
    background-image: url('./components/images/5415455.png'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  
  

  